<template>
	<div>
		<Header :title="isEdit ? '修改售后规则' : '新增售后规则'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
				:wrapperCol="{span: 16, xxl: 15 }" @finish="onSubmit">
				<div style="margin-top: 30px;">
					<a-form-item label="规则名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" placeholder="请输入分类标题"></a-input>
					</a-form-item>
					
					<a-form-item label="手续费配置" name="rules" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-button type="primary" @click="onAdd">新增</a-button>
						<a-form-item-rest>
							<a-table :columns="columns" :dataSource="modelRef.rules" :pagination="false">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'time'">
										<a-input-number v-model:value="record.time" :precision="0" placeholder="请输入"></a-input-number>
									</template>
									<template v-if="column.key === 'service'">
										<a-input-number v-model:value="record.service" :min="0" :precision="2" placeholder="请输入"></a-input-number> %
									</template>
									<template v-if="column.key === 'action'">
										<a-button type="link" danger size="small" @click="onDelete(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
							<div class="ui-warn">
								<span>退票时间：</span>正数代表开场前x分钟，负数代表开场后x分钟可申请退款，如果有两个正数或负数如60和120，则120代表开场前120分钟到开场前60分钟内；输入0，则所有时间都可退，将直接忽略其他退票时间
							</div>
							<div class="ui-warn">
								<span>服务费：</span>按照实付*百分比收取，0则不收取服务费；
							</div>
						</a-form-item-rest>
					</a-form-item>

					<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-switch v-model:checked="modelRef.isDisabled" :unCheckedValue="1" :checkedValue="0" checked-children="启用" un-checked-children="禁用" />
					</a-form-item>
				</div>
				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">{{ isEdit ? '提交' : '提交' }}</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">取消</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon';
	import Header from '@/components/header/header.vue';
	import { getPerformRefundRulesDetail, savePerformRefundRules, updatePerformRefundRules } from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			Header,
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				showModal: false,
				rowSelectionType: 'checkbox',
				selectedRowKeys: [],
				fileList: [],
				modelRef: {
					isDisabled: 1,
					rules: []
				},
				columns: [{
					title: '退票时间',
					key: 'time',
				}, {
					title: '服务费',
					key: 'service',
				}, {
					title: '操作',
					key: 'action',
				}],
			}
		},
		created() {
			if (this.isEdit && this.id) {
				this.getPerformRefundRulesDetail();
			}
		},
		methods: {
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				let ret;
				if (this.isEdit && this.id) {
					ret = await updatePerformRefundRules(postData);
				} else {
					ret = await savePerformRefundRules(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！')
					this.onBack(true);
				}
			},
			async getPerformRefundRulesDetail() {
				this.loading = true;
				let ret = await getPerformRefundRulesDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.modelRef = ret.data;
					this.loading = false;
				}
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onAdd() {
				this.modelRef.rules.push({
					rowIndex: this.modelRef.rules.length,
					time: '',
					service: ''
				})
			},
			onDelete(item, index) {
				this.modelRef.rules.splice(index, 1);
			}
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}

	.ui-recommended {
		position: relative;
	}

	.ui-seletced {
		margin-left: 10px;
		position: absolute;
		top: 15px;
		color: #aaaaaa;
	}

	.ui-form__item {
		margin-right: 30px;
	}
	
	.ui-warn {
		margin-top: 20px;
		font-size: 14px;
	}
	.ui-warn span {
		color: red;
	}
</style>